<template>
  <div class="vb-sidebar">
    <div v-if="loading">
    </div>
    <div v-else>
      <b-row>
        <b-col>
          <slot name="resume" />
        </b-col>
      </b-row>

      <b-row v-if="external_condition_lock">
        <b-col>
          <sidebar-external-message v-for="(external_condition, index) in external_checkout_conditions.filter(c => { return c.sidebar_validation == false })" :external_condition="external_condition" v-bind:key="_uid+index" />
        </b-col>
      </b-row>

      <b-row :class="{ 'vb-sidebar--locked': external_condition_lock }">
        <b-col>
          <slot name="message">
            <div v-if="completed" class="vb-sidebar__block--completed">
              Acquisto completato!<!--NB: lo slot di default non tiene conto dell'eventuale errore, che va gestito dal parent-->
            </div>
            <div v-if="!completed && purchasing" class="text-center">
              <loader>
                <template>
                  <span>Prenotazione in corso... non abbandonare o ricaricare la pagina!</span>
                </template>
              </loader>
            </div>
          </slot>

          <div v-if="!completed">
            <div v-if="!purchasing">
              <div v-for="(block,key) in sidebar_blocks" v-bind:key="_uid+key">
                <component
                  v-if="block.show"
                  @change="event => sidebar_block_update(key, event) /*ALL*/"
                  :validate_on_mount="validate_on_mount"
                  :class="'vb-sidebar__block--' + key + ' ' + (block.payload == false ? 'invalid' : 'valid')"
                  :ref="key /*ALL -> serve al callback di stripe*/"
                  :is="key /*ALL*/"
                  :live_price="live_price /*discount_codes*/"
                  :av_from_state="availability.details.integration /*discount_codes*/"
                  :dc_from_state="discount_codes /*discount_codes*/"
                  :payments="payments  /*payments*/"
                  :credit_card_amount="credit_card_amount /*stripe*/"
                />
              </div>
            </div>

            <div v-if="!purchasing">
              <div class="vb-sidebar__block--btn" v-if="has_slot('before_purchase')">
                <slot name="before_purchase" />
              </div>
            </div>
            
            <div class="vb-sidebar__block--btn" v-if="!purchasing">
              <h5 class="vb-heading">Procedi con l'acquisto</h5>
              <vb-button
                @click="handle_sidebar_purchase()"
                :vb_options="{
                  text: 'Prenota',
                  disabled: !disable_checkout_button(),
                  icon_disabled: 'lock'
                }"/>
              <span class="d-block text text--sm mt-2" v-if="!disable_checkout_button()" v-html="'Verifica di aver compilato tutti i campi correttamente e di avere accettato le condizioni d\'uso e di trattamento dei dati.'" />
            </div>
          </div>
          
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { number, round, sum, subtract, larger, smallerEq, format, largerEq } from 'mathjs'
import SidebarGuest from './sidebar-guest'
import SidebarDiscountCodes from './sidebar-discount-codes'
import SidebarPayments from './sidebar-payments'
import SidebarStripe from './sidebar-stripe'
import SidebarOtp from './sidebar-otp'
import SidebarBillToPax from './sidebar-bill-to-pax'
import SidebarResume from './sidebar-resume'
import SidebarExternalMessage from './sidebar-external-message'

export default {
  name: "sidebar",
  components: {
    'guest': SidebarGuest,
    'discount_codes': SidebarDiscountCodes,
    'payments': SidebarPayments,
    'stripe': SidebarStripe,
    'otp': SidebarOtp,
    'bill_to_pax': SidebarBillToPax,
    'resume': SidebarResume,
    'sidebar-external-message': SidebarExternalMessage,
  },
  props: {
    validate_on_mount: {
      type: Boolean,
      default: false,
    },
    external_checkout_conditions: {
      type: Array,
      default: () => {
        return []
      },
    },
    live_price: String,
    loading: Boolean,
    purchasing: Boolean,
    completed: Boolean,
    discount_codes: Array,
    integration: Object,
    availability: [Object],
  },
  created() { },
  computed: {
    external_condition_validation() {
      let external_condition_validation = true
      this.external_checkout_conditions.forEach(condition => {
        external_condition_validation = condition.sidebar_validation && external_condition_validation
      })
      return external_condition_validation
    },
    external_condition_lock() {
      if (this.external_condition_validation) return false // condizione esterne soddisfatte, validazione ok
      let sidebar_lock = this.external_checkout_conditions.filter(c => {
        return c.sidebar_lock
      })
      return (sidebar_lock.length > 0) // true se c'è almeno una condizione esterna bloccante
    },
    payments() {
        let welfare_to_use = smallerEq( number(this.live_price), this.availability.details.integration ) ? number(this.live_price) : this.availability.details.integration
        let payments = [
          {
            name: 'Credito',
            value: welfare_to_use,
            info: '',
          },
          {
            name: 'Buoni caricati',
            value: this.discount_codes_amount,
            info: '',
          },
          {
            name: 'Carta di credito',
            value: this.credit_card_amount,
            info: '',
          },
        ]
        return payments
    },
    credit_card_amount() {
      /**
       * welfare==true => no carta di credito
       */
      if (this.integration.welfare == true) return 0

      /**
       * pagamenti diversi dal credito welfare (per ora solo codici sconto)
       */
      let total_other_payments = this.discount_codes_amount

      /**
       * se (availability + altri pagamenti) coprono il live_price, non c'è da pagare niente con carta
       */
      if (largerEq(sum(this.availability.details.integration, total_other_payments), number(this.live_price))) return 0
      let residual = subtract( number(this.live_price), sum(this.availability.details.integration, total_other_payments) )
      return round(residual, 2)
    },
    payments_sum(){
      let tot = this.payments.reduce(function(accumulator, payment) {
        return sum(accumulator, payment.value)
      }, 0)
      return tot
    },
  },
  watch: {
    // NB: unico modo per gestire il flag "show" di stripe coerentemente al residuo lasciato dai codici sconto
    credit_card_amount() {
      this.sidebar_blocks.stripe.show = (this.credit_card_amount > 0)
    }
  },
  data() {
    return {
      booking_data_payments: {
        welfare: {},
        discountCodes: {},
        cc: {},
      },
      sidebar_blocks: {},
      bill_to_pax_required: this.integration.billToPax,
      otp_required: (this.integration && this.integration.otpRequired) ? this.integration.otpRequired : false,
      otp_check: false,
      stripe_completed: false,
      discount_codes_amount: 0,
    }
  },
  methods: {
    has_slot(slot_name) {
      return !!this.$slots[slot_name]
    },
    sidebar_block_update(key, event) {
      this.log('[sidebar_key_change] ' + key + ' - ' + event.name)
      this.sidebar_blocks[key].payload = event.payload
      switch (key) {
        case 'guest':
          /**
           * non più necessario. prima era: this.sidebar_blocks[key].payload = event.payload
           */
          break

        case 'bill_to_pax':
          /**
           * non più necessario. prima era: this.sidebar_blocks[key].payload = event.payload
           */
          break

        case 'discount_codes':
          this.handle_discount_codes_update(event)
          break
      
        case 'stripe':
          this.handle_stripe_update(event)
          break
             
        case 'otp':
          this.otp_check = event.payload
          if (event.name == 'otp-purchase') {
            if (this.stripe_completed == false && this.show_stripe()) return
            /**
             * this.handle_sidebar_purchase() => NO:
             * altrimenti nel caso in cui stripe non serve e l'utente ha i dati precompilati dal browsersi innesca
             * un automatismo che fa partire l'acquisto senza nessun click di conferma
             */
            this.sidebar_blocks.otp.show = false
          }
        break
          
        default:
          break
      }
    },
    handle_stripe_update(event) {
      if (event.name == 'stripe-init') {
        this.booking_data_payments.cc = {}
        this.stripe_completed = false
      }
      if (event.name == 'stripe-change') {
        // no callback provided
      }
      if (event.name == 'stripe-confirm') {
        if (event.payload.error) {
          this.stripe_completed = false
          /* obsoleto: lasciamo semplicemente la alert dentro il componente stripe
          window.eventBus.$emit('got_modal_notification',{
            msg: event.payload.message,
            status: event.payload.status,
            callback: function() {}
          })
          */
          return
        }
        this.booking_data_payments.cc.paymentIntentId = event.payload.paymentIntentId
        this.booking_data_payments.cc.nameSurname = event.payload.name
        this.stripe_completed = true
        this.sidebar_blocks.otp.show = this.show_otp()
        this.sidebar_blocks.stripe = event.payload
        this.$nextTick( () => {
          /**
           * nextTick() attende l'aggiornamento del DOM, serve altrimenti non trovo le $refs aggiornate
           * ed <otp /> in questo momento potrebbe non essere ancora montato nel dom)
           */
          if(this.show_otp()) {
            if (this.$refs.otp[0]) this.$refs.otp[0].trigger()
          }
          else {
            // rimosso automatismo stripe => checkout
            // this.handle_sidebar_purchase()
          }
        })
      }
    },
    handle_discount_codes_update(event) {
      this.discount_codes_amount = event.payload.discount_codes_amount
      this.$emit('commit', {
        name: 'discount_codes',
        payload: event.payload.discount_codes
      })
    },
    show_discount_codes() {
      /*
      let is_welfare = false
      if(this.integration) is_welfare = this.integration.welfare
      else is_welfare = true
      return !is_welfare && (this.integration.integration !== 'jointly')
      */
      return true
    },
    show_stripe() {
      return this.credit_card_amount > 0
    },
    show_otp() {
      /**
       * mostro otp se:
       * - è richiesto dal config e non è stato completato l'iter otp
       * - c'è un totale da caricare su carta di credito E la procedura stripe è completata
       */
      let otp_completed = this.otp_check == false,
          credit_card_required = (this.credit_card_amount > 0),
          credit_card_completed = credit_card_required && this.stripe_completed

      return this.otp_required && otp_completed && (credit_card_completed | !credit_card_required)
    },
    show_bill_to_pax() {
      return this.bill_to_pax_required
    },
    disable_checkout_button() {
      /**
       * clausole di uscita: condizioni che disabilitano il pulsante di checkout
       */
      if ( Object.keys(this.sidebar_blocks) == 0 ) return false

      /**
       * verifica clausole di uscita esterne (se esistono)
       */
      if (!this.external_condition_validation) return false
      
      if (this.bill_to_pax_required) {
        if ( this.sidebar_blocks.bill_to_pax.payload == false ) return false
      }
      
      if ( this.sidebar_blocks.guest.payload == false ) return false

      /**
       * OTP richiesto ma non ancora validato
       */
      if ( this.otp_required ) {
        if (this.otp_check!=='VALID' && this.otp_check!=='ALREADY_VALIDATED') return false
      }

      /**
       * serve integrare il pagamento con la carta ma ancora non si è completata la procedura di stripe
       */
      if ( this.credit_card_amount > 0 && !this.stripe_completed ) return false

      /**
       * la somma dei pagamenti disponibili è inferiore al live_price
       * TODO gestire eccezione Jointly (prenotare anche con credito inferiore)
       */
      if(this.availability.is_known && larger(this.live_price, this.payments_sum)) return false

      return true
    },
    handle_sidebar_purchase() {
      /**
       * controllo ridondante:
       * teoricamente se c'è qualche errore o campo mancante il tasto "Prenota" è disabilitato
       * MA: nel caso in cui si reintroducesse l'automatismo tra l'ok di stripe e la prenotazione, allora
       * questo controllo diventa importante perché il pulsante viene bypassato e si chiama direttamente il metodo
       */
      if (this.disable_checkout_button() == false) return

      this.sidebar_blocks.otp.show = false
      let amount = this.live_price

      /**
       * logica codici sconto (SVINCOLATA da flag welfare)
       */
      if( larger(this.discount_codes_amount, 0) ) amount = subtract(amount, this.discount_codes_amount)
      this.booking_data_payments.discountCodes = {}
      this.booking_data_payments.discountCodes.amount = (typeof this.discount_codes_amount == 'string' ? this.discount_codes_amount : format(this.discount_codes_amount))
      this.booking_data_payments.discountCodes.codes = this.discount_codes.map((d) => {
        return {
          discountCode: d.discountCode,
          amount: (typeof d.valueUsed == 'string' ? d.valueUsed : format(d.valueUsed)),
          valueNominal: (typeof d.valueNominal == 'string' ? d.valueNominal : format(d.valueNominal)),
          valueResidual: (typeof d.valueResidual == 'string' ? d.valueResidual : format(d.valueResidual)),
          expirationDate: d.expirationDate,
        }
      })

      /**
       * logica cc/stripe (VINCOLATA a flag welfare)
       */
      if (this.integration.welfare == false) {
        if( larger(this.credit_card_amount, 0) ) amount = subtract(amount, this.credit_card_amount)
        this.booking_data_payments.cc.amount = (typeof this.credit_card_amount == 'string' ? this.credit_card_amount : format(this.credit_card_amount))
      }

      this.booking_data_payments.welfare.amount = format(round(amount,2))

      this.$nextTick(() => {
        this.$emit('purchase', {
          bill_to_pax: this.sidebar_blocks.bill_to_pax,
          guest: this.sidebar_blocks.guest,
          payments: this.booking_data_payments,
        })
      })
    }
  },
  mounted() {
    this.sidebar_blocks = {
      guest: {
        payload: false,
        show: true,
      },      
      resume: {
        payload: true,
        show: true,
      },
      discount_codes: {
        payload: true,
        show: this.show_discount_codes(),
      },
      payments: {
        payload: true,
        show: true,
      },
      bill_to_pax: {
        payload: false,
        show: this.show_bill_to_pax(),
      },      
      stripe: {
        payload: false,
        show: this.show_stripe(),
      },
      otp: {
        payload: false,
        show: this.show_otp(),
      },      
    }
  },
}
</script>

<style lang="scss" scoped>
.vb-sidebar {
  &--locked {
    opacity: 0.5;
    pointer-events: none;
  }
  top: 10px;
  &__block {
    background:$white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: $border-radius;

    .vb-heading {
      display:block;
      margin-bottom: 1.25rem;
    }

    &.valid {}

    &.invalid {}

    &--completed {
      @extend .vb-sidebar__block;
      p {
        text-align: center;
      }
    }

    &--guest {
      @extend .vb-sidebar__block;
    }
    
    &--bill_to_pax {
      @extend .vb-sidebar__block;
    }

    &--discount_codes {
      @extend .vb-sidebar__block;
    }

    &--payments {
      @extend .vb-sidebar__block;
    }
    
    &--stripe {
      @extend .vb-sidebar__block;
    }

    &--otp {
      @extend .vb-sidebar__block;
    }

    &--resume {
      @extend .vb-sidebar__block;
    }

    &--external-message {
      @extend .vb-sidebar__block;
    }

    &--btn {
      @extend .vb-sidebar__block;
      p {
        text-align: center;
      }
      .btn {
        width:100%;
      }
    }
  }
}
</style>